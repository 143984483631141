import { useEffect, useReducer, useCallback, createContext } from "react";
import { CITY_UNLOCKED, STAR_RECEIVED } from "../constants";
import { useHttp } from "../hooks/http.hook";
import { mainReducer } from "../reducers/mainReducers";
import Loading from "../components/partial/Loading";

export const defaultContextValues = {
  activeTab: 0,
  box: {},
  client: {},
  tour: {},
  cities: {},
  unreadEvents: {},
  animationStar() {
    const event = this.unreadEvents.find(
      (element) => element.event === STAR_RECEIVED
    );
    return event?.client_city_id;
  },
  animationCity() {
    const event = this.unreadEvents.find(
      (element) => element.event === CITY_UNLOCKED
    );
    return event?.client_city_id;
  },
  isJoined() {
    return Boolean(this.client?.active_tour_id);
  },
  isGuest() {
    return Boolean(this.client?.guest);
  },
};

export const MainContext = createContext(defaultContextValues);

export const MainProvider = ({ children }) => {
  const [mainDetails, dispatch] = useReducer(mainReducer, defaultContextValues);
  const { loading, request } = useHttp();

  useEffect(() => {
    getMainDetails();

    return () => {};
  }, []);

  const getMainDetails = useCallback(() => {
    request("/client/tour").then(
      (res) => {
        dispatch({ type: "SET_MAIN_DETAILS", payload: res });
        console.log("🚀 main.context", res);
      },
      (err) => {
        console.log("🚀 err", err);
      }
    );
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <MainContext.Provider value={{ mainDetails, dispatch, getMainDetails }}>
          {children}
        </MainContext.Provider>
      )}
    </>
  );
};
